'use client';

import { PropsWithChildren, useMemo } from 'react';
import { CssBaseline } from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v15-appRouter';
import { CssVarsTheme, CssVarsThemeOptions, Experimental_CssVarsProvider as CssVarsProvider, experimental_extendTheme as extendTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { integrateThemeWithRouter } from '@qb/base-theme/base-theme-utils';
import { globalThemeOptions, BlockedOnDemoLinkBehavior, DefaultLinkBehaviour } from './global-theme';

/**
 * https://mui.com/material-ui/integrations/nextjs/#theming
 */
export function GlobalThemeProvider({
  tenantTheme,
  children,
  isDemoTenant
}: PropsWithChildren<{
  tenantTheme: CssVarsThemeOptions;
  isDemoTenant?: boolean;
}>) {
  const theme = useMemo(() => {
    const options = deepmerge(globalThemeOptions, deepmerge(tenantTheme, integrateThemeWithRouter(isDemoTenant ? BlockedOnDemoLinkBehavior : DefaultLinkBehaviour)));
    const extendedTheme = extendTheme(options);

    // @ts-expect-error - remove the `dark` color scheme to optimize the HTML size for server-side application
    delete extendedTheme.colorSchemes.dark;
    return (extendedTheme as CssVarsTheme);
  }, [isDemoTenant, tenantTheme]);
  return <AppRouterCacheProvider data-sentry-element="AppRouterCacheProvider" data-sentry-component="GlobalThemeProvider" data-sentry-source-file="GlobalThemeProvider.tsx">
      <CssVarsProvider theme={theme} data-sentry-element="CssVarsProvider" data-sentry-source-file="GlobalThemeProvider.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="GlobalThemeProvider.tsx" />
        {children}
      </CssVarsProvider>
    </AppRouterCacheProvider>;
}