'use client';

import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionSummary, AccordionDetails, Box, Button, Container, Link as MuiLink, Stack, Typography } from '@mui/material';
import { ReactComponent as IconRobot } from '@qb/frontend/components/assets/iconRobot.svg';
import { ReactComponent as IconRocket } from '@qb/frontend/components/assets/iconRocket.svg';
export const DemoBanner = () => {
  const [expanded, setExpanded] = useState(false);
  const handleChange = (_event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };
  return <Accordion square disableGutters elevation={0} expanded={expanded} onChange={handleChange} sx={{
    position: 'relative',
    border: 0,
    bgcolor: 'common.black'
  }} data-sentry-element="Accordion" data-sentry-component="DemoBanner" data-sentry-source-file="DemoBanner.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="DemoBanner.tsx">
        <AccordionSummary expandIcon={<ExpandButton expanded={expanded} />} aria-label="Expand demo info" aria-controls="demo-banner-content" id="demo-banner-header" sx={{
        py: 3,
        px: 0,
        bgcolor: 'common.black',
        zIndex: 1,
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'none'
        }
      }} data-sentry-element="AccordionSummary" data-sentry-source-file="DemoBanner.tsx">
          <Stack direction="row" spacing={{
          xs: 0,
          md: 3
        }} sx={{
          alignItems: 'center'
        }} data-sentry-element="Stack" data-sentry-source-file="DemoBanner.tsx">
            <Box sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }} data-sentry-element="Box" data-sentry-source-file="DemoBanner.tsx">
              <IconRobot data-sentry-element="IconRobot" data-sentry-source-file="DemoBanner.tsx" />
            </Box>
            <Typography variant="h2" sx={{
            color: 'common.white'
          }} data-sentry-element="Typography" data-sentry-source-file="DemoBanner.tsx">
              Welcome to the Sales Pilot Demo!
            </Typography>
            <Box sx={{
            display: {
              xs: 'none',
              md: 'block'
            }
          }} data-sentry-element="Box" data-sentry-source-file="DemoBanner.tsx">
              <IconRocket data-sentry-element="IconRocket" data-sentry-source-file="DemoBanner.tsx" />
            </Box>
          </Stack>
        </AccordionSummary>
      </Container>
      <AccordionDetails id="demo-banner-content" sx={theme => ({
      position: 'absolute',
      bgcolor: 'common.black',
      width: '100%',
      pt: 2,
      pb: 8,
      transform: expanded ? 'translateY(0)' : 'translateY(-100%)',
      transition: theme.transitions.create(['transform'])
    })} data-sentry-element="AccordionDetails" data-sentry-source-file="DemoBanner.tsx">
        <Container data-sentry-element="Container" data-sentry-source-file="DemoBanner.tsx">
          <Typography variant="paragraph" sx={{
          color: 'common.white'
        }} data-sentry-element="Typography" data-sentry-source-file="DemoBanner.tsx">
            {`We've designed this demo site to give you a glimpse of Sales Pilot
            in action. While it offers a hands-on experience, please note that
            certain functionalities are limited in this demo version. Should you
            have any questions or need help, feel free to reach out to us at `}
            <MuiLink href="mailto:salespilot@quotebeam.com" underline="always" color="inherit" data-sentry-element="MuiLink" data-sentry-source-file="DemoBanner.tsx">
              salespilot@quotebeam.com
            </MuiLink>
          </Typography>
        </Container>
      </AccordionDetails>
    </Accordion>;
};
type ExpandButtonProps = {
  expanded: boolean;
};
const ExpandButton = ({
  expanded
}: ExpandButtonProps) => {
  const buttonText = expanded ? 'See less' : 'See more';
  const iconTransform = expanded ? 'rotate(-180deg)' : 'none';
  return <Button endIcon={<ExpandMore sx={theme => ({
    transform: iconTransform,
    transition: theme.transitions.create(['transform'])
  })} />} sx={{
    minWidth: 120,
    justifyContent: 'space-between',
    bgcolor: 'common.white',
    color: 'common.black',
    '&:hover, &:active': {
      bgcolor: 'common.white',
      color: 'common.black'
    }
  }} data-sentry-element="Button" data-sentry-component="ExpandButton" data-sentry-source-file="DemoBanner.tsx">
      {buttonText}
    </Button>;
};