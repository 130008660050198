import { PropsWithChildren } from 'react';
import { Box, Link as MuiLink, Stack, Tooltip, TooltipProps, Typography } from '@mui/material';
import { ReactComponent as IconRobot } from './assets/iconRobot.svg';
import { ReactComponent as IconRocket } from './assets/iconRocket.svg';
export const UnavailableFeatureTooltip = ({
  children
}: PropsWithChildren<TooltipProps>) => <Tooltip arrow componentsProps={{
  tooltip: {
    sx: {
      py: 1,
      pl: 1,
      pr: 2,
      maxWidth: 500,
      borderRadius: 0,
      bgcolor: 'tertiary.dark'
    }
  },
  arrow: {
    sx: {
      color: 'tertiary.dark'
    }
  }
}} title={<Stack direction="row" spacing={2} sx={{
  alignItems: 'center',
  py: 1
}}>
        <Box sx={{
    flexBasis: 60,
    transform: 'rotate(30deg)'
  }}>
          <IconRobot />
        </Box>
        <Box>
          <Typography sx={{
      color: 'tertiary.contrastText',
      fontWeight: 600,
      pb: 0.5
    }}>
            This feature is currently unavailable in the Sales Pilot Demo
            environment.
          </Typography>
          <Stack direction="row">
            <Typography variant="bodyMedium" sx={{
        color: 'tertiary.contrastText'
      }}>
              {`If you're interested in learning more, please reach out to
                us via `}
              <MuiLink href="mailto:salespilot@quotebeam.com" color="tertiary.contrastText">
                salespilot@quotebeam.com
              </MuiLink>
              .
            </Typography>
            <Box sx={{
        flexBasis: 60,
        ml: -1.5,
        mt: -1
      }}>
              <IconRocket />
            </Box>
          </Stack>
        </Box>
      </Stack>} data-sentry-element="Tooltip" data-sentry-component="UnavailableFeatureTooltip" data-sentry-source-file="UnavailableFeatureTooltip.tsx">
    {children}
  </Tooltip>;